/* General */
.centrar{
    display: flex;
    justify-content: center;
}
.text-center{
    text-align: center;
}
/* General */
/* Layout */
.header-container{
    position: fixed;
    z-index: 10;
    width:100%;
}
.content-container{
    padding-top: 64px;
}
.content-main-container{
    min-height: calc(100vh - 64px);
}
.map-container{
    width: calc(100vw - 17px);
    height: 100vh;
    background-image: url('../assets/Mapa.webp');
    background-size: 90%;
    background-position-x: calc(50% + 60px);
    background-position-y: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 0;
}
.footer-container{
    padding: 0px !important;
}
/* Layout */
/* Navbar */
.header {
    display: flex;
    align-items: center;
    background-image: linear-gradient(to bottom, var(--navbar_primary_color), var(--navbar_secondary_color)) !important;
    box-shadow: none !important;
    padding: 0px !important;
}
.header-search-container{
    width: 90%;
    text-align: center;
}
.header-search-input{
    border-radius: 20px !important;
    width: 85% !important;
}
.header-icon{
    color: var(--navbar_text_color);
}
.header-drawer-container{
    display: none;
}
.header-drawer-carpet{
    display: none;
}
@media (max-width: 768px) {
    .header-drawer-container{
        display: block;
    }
    .header-drawer-carpet{
        display: block;
    }
}
.header-drawer .ant-drawer-content{
    background-color: var(--background_color);
}
.header-drawer-nombre{
    font-size: 18px;
    margin: 3px 0px 0px 0px;
    color: var(--navbar_text_color);
}
.header-drawer-email{
    font-size: 12px;
    color: var(--navbar_text_color);
}
.header-drawer-hr{
    margin: 0;
    border: solid 1px var(--navbar_text_color);
}
.header-drawer-item{
    cursor: pointer;
    margin-top: 10px;
}
.header-drawer-image{
    width: 30px !important;
    height: 30px !important;
    margin-right: 5px;
}
.header-drawer-text{
    font-size: 14px;
    color: var(--navbar_text_color);
}
/* Navbar */
/* Footer */
.footer-login{
    background-color: var(--background_color);
}
.footer-icon-container{
    padding-top: 10px;
    padding-bottom: 10px;
}
.footer-icon{
    color: var(--footer_text_color);
}
.footer-text{
    color: var(--footer_text_color);
    text-align: center;
}
/* Footer */
/* Sidebar */
.content-sidebar{
    background-color: var(--sidebar_background_color) !important;
    z-index: 1;
}
@media (max-width: 768px) {
    .content-sidebar{
        display: none;
    }
}
.sideber-item{
    cursor: pointer;
    margin-top: 10px;
}
.sidebar-user-img{
    width: 60px !important;
    height: 60px !important;
    border-radius: 100%;
    border: 2px solid var(--primary) !important;
}
.sidebar-text{
    color: var(--sidebar_text_color);
    text-align: center;
    font-size: 12px;
}
/* Sidebar */
/*-----------usuario-perfil------*/
.imagen-perfil{
    display:'block';
    width: 70% !important;
    margin: 30px auto ; 
}
.menu-item{
    display: inline-block !important; 
    align-items: center !important;
    text-align: center !important; 
}
.menu-item:hover{
    color: var(--primary) !important;
}
.item{
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
}
.link:hover{color: var(--primary) !important;
  
}
.layout{
    padding-left: 0 !important;
    width: 100% !important;
    height: 50% !important
   
}

.tabs{
    color: var(--primary);
    font-size: 16px; 
}
.sider{
    background-color: white !important;
    margin: 16px;
}
/*----------------------------------------------------------*/

 /*----------------------esto se usa en ActividadesUsuario.js----------------*/
 .p-card:hover {
    cursor: pointer;
}
/*------------------------------------------------------------------------*/

/*----------------------------eso se usa en FormUsuario.js---------------------*/
@media only screen and (max-width: 1000px) {
    .inputs{
        max-width: 285px !important;
        max-height: 100px  !important;
    }
    .ant-tabs-nav-list{
        flex-direction: column !important;
        max-width: 285px !important;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
        background-color:white;
    }
    .ant-tabs-tab{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
    .layout,.layout-usuario{
        padding-left: 0 !important;
    }
    .btn-forms{
        background-color: var(--primary) !important;
        border: 1px solid var(--primary) !important;
        float: left;
    }
}

.img-holder{
    margin: auto;
    width: 206px;
    height: 206px;
    border: 3px solid black;
    border-radius: 5px;
    margin-top: 1rem;
}
.image-upload{
    margin: 1p 1px 1px 1px;
    width: 206px;
    height: 30px;
    background-color: black;
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
#input[type="file"]{
    display: none;
}
.label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

/*-------------------------------------------------------------------------------

/*------------------------------------esto se usa en MaestroScreen.js--------------*/
.btn-atras{
    padding: 20px 20px 0px !important;
    color: var(--primary) !important;
    font-size: 20px !important;
    font-weight: bold !important;
    display: inline;
}
.icon-atras{
    font-size: 17px;
}
.admin-maestro-card{
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px #8888;
    border-radius: 10px;
    margin: 20px;
    padding: 50px 50px 20px;
}
.admin-maestro-info{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-maestro-img{
    height: 200px !important;
    width: 200px !important;
    border: double 5px transparent;
    border-radius: 50%;
    background-image:   linear-gradient(var(--primary), #659ED4), 
                        linear-gradient(to right, var(--primary), #659ED4);
    background-origin: border-box;
    background-clip: content-box, border-box;
}
.admin-maestro-iniciales{
    height: 200px !important;
    width: 200px !important;
    border-radius: 50%;
    font-size: 100px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.admin-maestro-nombre{
    font-size: 25px;
    padding: 20px 20px 0px;
}
.bold{
    font-weight: bold !important;
}
.admin-maestro-profesion{
    font-size: 18px;
    color: #888;
}
.admin-maestro-resumen{
    border: solid 1px #c5c5c588;
    margin: 20px 15px;
    padding: 20px;
}
.maestro-cursos-texto{
    font-size: 30px !important;
    color: #999;
    margin: 15px !important;
}
.maestro-cursos{
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.card-header{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid gray !important;
}
.no-disponible{
    display: flex;
    justify-content: center;
    padding: 20px;
    font-size: 20px;
    width: 100%;
}

/*------------------------MaestrosScreen.js-------------------------------*/
.input-buscador{
    font-size: 25px !important;
    height: 40px !important;
    width: 100%;
    margin-top: 20px;
}
.search-icon{
    color:#9ed52b  !important;
    font-size: 20px;
}
.maestro-card{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.maestro-card-img{
    width: 90px !important;
    height: 90px !important;
    border-radius: 50% !important;
    border: double 3px transparent !important;
    background-image:   linear-gradient(var(--primary), #659ED4), 
                        linear-gradient(to right, var(--primary), #659ED4) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
}
.maestro-card-iniciales{
    width: 90px !important;
    height: 90px !important;
    border-radius: 50% !important;
    font-size: 40px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.maestro-card-nombre{
    font-size: 20px;
}
.maestro-card-profesion{
    font-size: 15px;
    color:#999;
}
.maestro-card-skeleton{
    display: flex;
    flex-direction: column;
    align-items: center;   
}

.maestro-skeleton-img{
    width: 90px !important;
    height: 90px !important;
    margin-bottom: 10px;
}
.maestros-filtros-card{
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.maestros-filtros{
    color: var(--primary);
    font-size: 20px;
    padding: 20px;
}
/*-------------------------------------------------------------------*/

/*--------------------------PasswUsuario.js--------------------------------------*/
.btn-forms{
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    float: left !important;
}
/*-----------------------------------------*/
/*ViewCursoUsuuario.js -> Inicio */
.card-view-curso{
    border-radius: 10px !important;
    box-shadow: 6px 5px 13px 0px #9595951f;
}
.image-container {
    border-radius: 15px 15px 0px 0px;
    position: relative;
    width: 100%;
    height: 200px !important;
}
.image-container > .ant-image > .ant-image-img{
    border-radius: 10px 10px 0px 0px !important;
}
.btn-view-curso{
    background-color: var(--primary) !important;
    color: white !important;
    border-radius: 5px !important;
    font-weight: bold !important;
    font-size: 18px !important;
    height: fit-content !important;
}
.btn-view-curso:hover{
    background-color: var(--primary_hover) !important;
    border-color: transparent !important;
}
.info-view-curso{
    color: #000 !important;
    margin-top: 10px;
}
.titulo-view-curso{
    border: none !important;
    font-size: 38px;
    font-weight: bold;
}
.subtitulo-view-curso{
    margin-top: 20px;
    font-size: 30px;
}
.desc-view-curso{
    border: none !important;
    padding: 0px !important;
    font-size: 19px !important;
    color: grey !important;
}
.desc-view-curso[disabled]{
    cursor: default !important;
}
/*ViewCursoUsuuario.js -> Inicio */
.input-desc{
    border: 1px solid rgb(220, 220, 220) !important;
    height: 127px !important;
}
.input-desc:hover {
    border: 1px solid rgb(220, 220, 220) !important;
}

.input-desc:focus {
    box-shadow: none !important;
}
.btn-preg-resp {
    border-color: var(--primary)  !important;
    color:var(--primary) !important;
    font-weight: bold !important;
}

@media (min-width: 770px) and (max-width: 950px) {
    .btn-preg-resp{
        font-size: 1.3vw !important;
    }
}


/* Index Cursos */

.index-cursos-usuario {
    /* background-color: #0f172f; */
    width: 100%;
    min-height: calc(100vh - 60px);
}
.ant-layout{
    background: transparent !important;
}

.forotxt:focus{
    outline: none !important;
}



/* Boton Reset Curso */

.btn-reset-curso {
    background-color: var(--primary) !important;
    color: white !important;
}

.btn-reset-curso:focus {
    outline: none !important;
    box-shadow: none !important;
}

.hola{
    padding: 3% 0% 3% 3% !important; 
}
@media only screen and (max-width: 500px) {
    .hola{
        padding: 3% 3% 3% 3% !important;
    }
}

.hola-view-make-course{
    padding: 3% 0% 3% 0% !important; 
}




@media only screen and (max-width: 500px) {
    .edip{
        visibility: hidden !important;
        
    }
}
.viewMakeCourse-actividad-titulo{
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding: 10px 3%;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(90%);
    overflow: hidden;
}
.viewMakeCourse-actividad-end-text{
    padding: 10px 10%;
    color: #fff;
    font-size: 18px;
    text-align: justify;
}
.viewMakeCourse-actividad-descarga-text{
    padding: 10px 10%;
    color: #fff;
    font-size: 12px;
    text-align: center;
}
.viewMakeCourse-actividad-back-text{
    padding: 10px 10%;
    color: #fff;
    font-size: 12px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}
.viewMakeCourse-actividad-content{
    height: 60vh;
    overflow: auto;
}
.viewMakeCourse-titulo{
    color: #fff;
    margin-bottom: 5px;
    font-size: 16px;
}
.viewMakeCourse-img{
    width: 50px;
    height: 50px;
    background-color: khaki;
    border-radius: 50%;
    margin-right: 15px;
}
.viewMakeCourse-iniciales{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    font-size: 24px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.viewMakeCourse-docente-img{
    width: 20px;
    height: 20px;
    background-color: khaki;
    border-radius: 50%;
}
.viewMakeCourse-actividad{
    cursor: pointer !important;
}
.viewMakeCourse-actividad-disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.viewMakeCourse-docente-nombre{
    color: gray;
    font-size: 14px;
}
.viewMakeCourse-tema{
    color: #fff;
    border-bottom: 0.5px solid #263658;
    padding: 10px 20px 10px 5px;
    margin-left: 10px;
    min-height: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: calc(95%);
    overflow: hidden;
}
.viewMakeCourse-tema-cursor{
    cursor: default;
}
.ant-layout-sider-collapsed > .ant-layout-sider-children > .viewMakeCourse-act{
    padding-left: 2px !important;
}
.viewMakeCourse-act{
    padding-left: 15px;
}
.viewMakeCourse-numero{
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0px 10px;
    font-size: 12px;
    line-height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(82 103 140);
    color: rgb(27 42 73);
    font-weight: bold;
    margin-left: 10px;
}
.viewMakeCourse-numero-done{
    background-color: rgb(137 191 36);
}
.viewMakeCourse-numero-actual{
    background-color: #fff !important;
}
.viewMakeCourse-sider-btn{
    background-color: rgb(50 162 255) !important;
    border: none !important;
    border-radius: 0px !important;
    color: rgb(4 12 28)!important;
    width: 44px !important;
    padding: 0px !important;
    height: 50px !important;
    min-width: 44px !important;
}
.viewMakeCourse-sider-titulo{
    color: #fff;
    padding: 10px;
    text-overflow: ellipsis;
    white-space:nowrap;
    display:inline-block;
    width: calc(90%);
    overflow: hidden;
    height: 50px;
    display: flex;
    align-items: center;
}
.viewMakeCourse-preguntar-container{
    margin: 15px;
    background-color:  #1b2a49 !important;
    border-radius: 5px !important;
}
.viewMakeCourse-preguntar{
    padding: 15px !important;
}
.viewMakeCourse-preguntar > .ant-form-item{
    margin: 0px !important;
}
.viewMakeCourse-preguntar input{
    background-color: #0f172f !important;
    color: #405377 !important;
    border: 1px solid #405377 !important;
    padding: 10px 10px;
    margin: 0px;
}
.viewMakeCourse-preguntar input:focus{
    color: #405377 !important;
}
.viewMakeCourse-pregunta{
    background-color: rgb(27, 42, 73);
    padding: 10px;
    margin: 15px;
    border-radius: 5px;
    color: #fff;
}
.viewMakeCourse-numero-calificar{
    background-color: rgb(50, 162, 255);
    border: none; 
    color: white;
    width: 200px;
}
.viewMakeCourse-progress-div{
    margin: 15px;
}
.viewMakeCourse-progress-container{
    display: flex;
    justify-content: center;
}
.viewMakeCourse-progress-title{
    font-size: 16px;
    color: var(--primary);
    text-align: center;
}
.viewMakeCourse-progress-percentage{
    font-size: 14px;
    color: #FFF;
    text-align: center;
    margin-top: 5px;
}
.viewMakeCourse-progress-reset-btn{
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    margin-top: 5px;
}
.viewMakeCourse-rate-title{
    font-size: 14px;
    color: var(--primary);
    text-align: center;
}
.viewMakeCourse-rate-msg{
    color: #888;
    font-size: 12px;
}
.viewMakeCourse-rate-value{
    color: #FFF;
    font-size: 15px;
    margin-right: 3px;
}
.pregunta-delete-icon:hover{
    color: #383838;
}
.viewMakeCourse-pregunta-img{
    border-radius: 50% !important;
    height: 40px !important;
    width: 40px !important;
}
.viewMakeCourse-pregunta-texto{
    font-size: 18px;
    font-weight: bold;
    margin-left: 60px;
}
.pregunta-usuario{
    font-size: 15px;
    float: right;
}
.pregunta-movil{
    display: none;
}
.ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: 2px solid #32a2ff !important;
    opacity: 0.5;
}
.viewMakeCourse-activities-buttons{
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 3%;
    padding-left: 3%;
    margin-top: 10px;
}
.usuarioTextScreen-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab{
    background: transparent !important;
    border: none !important;
    border-bottom: 2px solid transparent !important;
    width: 200px;
    justify-content: center;
    opacity: 0.5;
}
.usuarioTextScreen-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active{
    background: transparent !important;
    border: none !important;
    border-bottom: 2px solid #32a2ff !important;
    width: 200px;
    justify-content: center;
    opacity: 1 !important;
}
.usuarioTextScreen-content{
    padding: 50px;
}
.usuarioTextScreen-content p{
    background-color: transparent !important;
    color: white !important;
}
.usuarioTextScreen-nombre{
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    margin: 20px 0px 15px 0px;
}
.usuarioTextScreen-tab{
    color: #32a2ff;
}
.usuarioTextScreen-recurso-nombre{
    font-size: 14px;
    margin-left: 10px;
    color: #fff;
}
.usuarioTextScreen-recurso-nombre a {
    color: #fff;
}
.usuarioTextScreen-btn-prev{
    border: 1px solid rgb(50 162 255) !important;
    color: rgb(50 162 255) !important;
    background: transparent !important;
    border-radius: 2px 0px 0px 2px !important;
}
.usuarioTextScreen-btn-prev.ant-btn:hover{
    border-color: rgb(50 162 255) !important;
}
.usuarioTextScreen-btn-next{
    color: #0f172f !important;
    background-color: rgba(50 162 255) !important;
    border-color: rgb(50 162 255) !important;
    font-weight: 500 !important;
    border-radius: 0px 2px 2px 0px !important;
}
.usuarioTextScreen-btn-next-disabled{
    color: #0f172f !important;
    background-color: rgb(50 162 255 / 74%) !important;
    border-color: rgb(50 162 255 / 74%) !important;
    font-weight: 500 !important;
    border-radius: 0px 2px 2px 0px !important;
}
.usuarioQuizScreen-pregunta{
    background-color: rgb(27, 42, 73) !important;
    border: rgb(27, 42, 73) !important
}
.usuarioQuizScreen-pregunta-correcta{
    background-color: rgb(27, 42, 73) !important;
    border-color: rgba(136, 185, 35, 0.6) !important;
}
.usuarioQuizScreen-pregunta-incorrecta{
    background-color: rgb(27, 42, 73) !important;
    border-color: rgba(255, 0, 0, 0.6) !important;
}
.usuarioQuizScreen-pregunta > .ant-card-head{
    background: rgb(82 103 140) !important;
    border-bottom: rgb(82 103 140) !important;
    color: #fff !important;
}
.usuarioQuizScreen-pregunta-correcta > .ant-card-head{
    background: rgb(82 103 140) !important;
    border-bottom: rgba(136, 185, 35, 0.6) !important;
    color: #fff !important;
}
.usuarioQuizScreen-pregunta-incorrecta > .ant-card-head{
    background: rgb(82 103 140) !important;
    border-bottom: rgba(255, 0, 0, 0.6) !important;
    color: #fff !important;
}
.ant-radio-disabled + span{
    color: #FFF !important;
}
.usuarioQuizScreen-pregunta span{
    color: #fff !important;
}
aside.ant-layout-sider.ant-layout-sider-dark::-webkit-scrollbar {
    display: none;
}
/*Video tracker*/
.usuarioVideoScreen-video-range {
    height: 25px;
    -webkit-appearance: none;
    background: transparent;
}
.usuarioVideoScreen-video-range:focus {
    outline: none;
}
.usuarioVideoScreen-video-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: transparent;
    border-radius: 1px;
    border: 0px solid #000000;
}
.usuarioVideoScreen-video-range::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid var(--primary);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: var(--primary_hover);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}
.usuarioVideoScreen-video-range:focus::-webkit-slider-runnable-track {
    background: transparent;
}
.usuarioVideoScreen-video-range::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: transparent;
    border-radius: 1px;
    border: 0px solid #000000;
}
.usuarioVideoScreen-video-range::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid var(--primary);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: var(--primary_hover);
    cursor: pointer;
}
.usuarioVideoScreen-video-range::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.usuarioVideoScreen-video-range::-ms-fill-lower {
    background: var(--primary);
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
.usuarioVideoScreen-video-range::-ms-fill-upper {
    background: var(--primary);
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
.usuarioVideoScreen-video-range::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid var(--primary);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: var(--primary_hover);
    cursor: pointer;
}
.usuarioVideoScreen-video-range:focus::-ms-fill-lower {
    background: var(--primary);
}
.usuarioVideoScreen-video-range:focus::-ms-fill-upper {
    background: var(--primary);
}
.usuarioVideoScreen-video-range-volume {
    height: 25px;
    -webkit-appearance: none;
    background: transparent;
}
.usuarioVideoScreen-video-range-volume:focus {
    outline: none;
}
.usuarioVideoScreen-video-range-volume::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: transparent;
    border-radius: 1px;
    border: 0px solid #000000;
}
.usuarioVideoScreen-video-range-volume::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid white;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}
.usuarioVideoScreen-video-range-volume:focus::-webkit-slider-runnable-track {
    background: transparent;
}
.usuarioVideoScreen-video-range-volume::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: transparent;
    border-radius: 1px;
    border: 0px solid #000000;
}
.usuarioVideoScreen-video-range-volume::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid white;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: white;
    cursor: pointer;
}
.usuarioVideoScreen-video-range-volume::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.usuarioVideoScreen-video-range-volume::-ms-fill-lower {
    background: white;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
.usuarioVideoScreen-video-range-volume::-ms-fill-upper {
    background: white;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
.usuarioVideoScreen-video-range-volume::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid white;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: white;
    cursor: pointer;
}
.usuarioVideoScreen-video-range-volume:focus::-ms-fill-lower {
    background: white;
}
.usuarioVideoScreen-video-range-volume:focus::-ms-fill-upper {
    background: white;
}
.usuarioVideoScreen-video-range-progress{
    height: 5px;
    background-color: var(--primary);
}
.usuarioVideoScreen-video-range-progress-volume{
    height: 5px;
    background-color: white;
}
.usuarioVideoScreen-video-range-loaded{
    height: 5px;
    background-color: #9f9f9f7d;
}
.usuarioVideoScreen-video-range-full{
    height: 5px;
    background-color: #9a9a9a17;
    width: 100%;
}
.usuarioVideoScreen-video-range-container{
    position: absolute;
    width: 100%;
    height: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0;
    left: 0;
}
.usuarioTextScreen-btn-player{
    color: #fff !important;
    background-color: transparent !important;
    border: none !important;
    font-weight: 500 !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 25px !important;
}
.usuarioTextScreen-btn-player-disabled{
    opacity: 0.5;
}
.usuarioVideoScreen-error-text{
    color: white;
}
.usuarioVideoScreen-transition-container{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 80%);
    color: white !important;
}
.usuarioVideoScreen-transition-icon{
    font-size: 40px;
}
.usuarioVideoScreen-transition-info{
    font-size: 34px;
    font-weight: bold;
}
.usuarioVideoScreen-transition-cancel{
    font-size: 20px; 
    text-decoration: underline;
    cursor: pointer;
}
.usuarioVideoScreen-controls-container{
    position: absolute;
    left: 0;
    bottom: 0;
}
.usuarioVideoScreen-controls-button-icon{
    color: white;
    font-size: 20px;
}
.usuarioVideoScreen-controls-time{
    display: inline;
    color: white;
    float: right;
    margin-right: 10px;
}
/*Video tracker*/