@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap);
:root {
    --primary: #88b923;
    --primary_hover: #a2f100;
    --secondary: #659ED4;
  }

/*      */
.centrar{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.admin-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: calc(100vh - 67px) !important;
    padding: 2% 10% 0 10%;
}
.admin-input-buscador{
    font-size: 25px !important;
    height: 40px !important;
    width: 100%;
}
.admin-btn-atras{
    padding: 20px 0px !important;
    color: #88b923 !important;
    font-size: 20px !important;
    font-weight: bold !important;
    display: inline;
}
/*      */


/* Login -> Inicio */
.login-admin{
    background-image: url(/static/media/loginAdmin.50cd402f.png);
    background-repeat: no-repeat;
    background-size: cover;
    
}
.auth-center-admin{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; 
    height: 100vh;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left: 15%;
}
/* Login -> Fin */

/* Navbar -> Inicio */
.navbar-admin{
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-image: linear-gradient(90deg, #88b923, #659ED4) 0 0 100%;
    border-image: linear-gradient(90deg, var(--primary), var(--secondary)) 0 0 100%;
}
.icon-menu-navbar-clicked{
    color: #000 !important;
    font-size: 20px !important;
    -webkit-transform: translateX(170px);
            transform: translateX(170px);
    transition: all .25s;
}
.icon-menu-navbar{
    color: #000 !important;
    font-size: 20px !important;
    transition: all .25s;
}
.logout-navbar{
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    font-size: 20px !important;
}
.logout-navbar:hover{
    color: #88b923 !important;
    color: var(--primary) !important;
}

/*Navbar -> Fin */

/* Sidebar -> Inicio */
.sider-admin{
    background-color: #fff !important;
    padding: 10px 0px !important;
}
.usuario-container-sidebar{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 20px;
    -webkit-align-items: center;
            align-items: center;
    font-size: 20px;
}
.titulo-sidebar{
    color: #6a6a6a;
}
.subtitulo-sidebar{
    color: #aeaeae;
    font-size: 17px;
    margin-top: -5px !important;
}
.icon-user-sidebar{
    font-size: 70px;
    background-color: #6a6a6a;
    border-radius: 50%;
    padding: 5px;
    color: #fff !important;
}
.menu-item-sidebar{
    border-bottom: 2px solid #dcdcdc !important;
}
.icon-menu-sidebar{
    font-size: 20px !important;
    color: #88b923 !important;
    color: var(--primary) !important;
}
/* Sidebar -> Fin */

/* CRUDS general -> Inicio */
.admin-crud-titulo{
    font-size: 45px;
    text-align: right;
    margin-bottom: 0px !important;
    color: #88b923;
    color: var(--primary);
}
.nuevo-boton{
    background-color: #88b923 !important;
    background-color: var(--primary) !important;
    border: none !important;
    color: #fff !important;
    font-size: 20px !important;
    margin-right: 20px !important;
    padding: 5px 50px !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: 40px !important;
}
.nuevo-boton:hover{
    background-color: #9ed52b  !important;
    border: none !important;
    color: #fff !important;
}
.input-buscador{
    border: none !important;
    font-size: 25px !important;
    height: 40px !important;
    width: 100%;
}
.search-icon{
    color:#9ed52b  !important;
    font-size: 20px;
}
.ant-pagination-item-active, .ant-pagination-item-active a{
    border-color: #9ed52b  !important;
    color: #9ed52b  !important;
}
.ant-pagination-item:hover, .ant-pagination-item:hover a{
    border-color: #9ed52b  !important;
    color: #9ed52b  !important;
}
/* CRUDS general -> Fin */

/*Footer -> Inicio*/
.admin-footer-container{
    margin-top: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 20px;
    color: #787878;
    background-color: #f0f2f5;
    padding: 30px;
}
.icon-footer-admin{
    color: #787878 !important;
    font-size: 30px;
}
/*Footer -> Fin*/

/*Crud cursos -> Inicio*/
.admin-cursos-img{
    height: 170px;
}
@media (max-width: 1900px){
    .admin-cursos-img{
        height: 120px;
    }
}
/*Crud cursos -> Fin*/

@media (max-width: 500px){
    .crud-center{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}

/*Curso -> Inicio*/
.admin-curso-card{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 50px;
    height: 100%;
    margin: 20px 0px;
}
@media (max-width: 500px){
    .admin-curso-card{
        padding: 10px 20px;
    }
}
.admin-curso-outline{
    padding: 5px 20px;
    border: solid #d9d9d9 1px;
    height: 100%;
}
.admin-curso-title{
    font-size: 50px !important;
}
@media (max-width: 1900px){
    .admin-curso-title{
        font-size: 45px !important;
    }
}
@media (max-width: 500px){
    .admin-curso-title{
        font-size: 35px !important;
        text-align: center;
    }
    .admin-curso-side{
        display: none !important;
    }
}
.admin-curso-subtitle{
    font-size: 25px !important;
    color: #8e8f8f;
}
@media (max-width: 500px){
    .admin-curso-subtitle{
        font-size: 20px !important;
        color: #8e8f8f;
    }
}
.admin-curso-subtitle-2{
    cursor: pointer !important;
    font-size: 22px !important;
    margin: 20px 0px !important;
}
.admin-curso-subtitle-2:hover{
    color: #88b923 !important;
    color: var(--primary) !important;
    opacity: 70% !important;
    background: none !important;
}
.admin-curso-subtitle-2:active{
    background: none !important;
}
.admin-curso-subtitle-2-selected{
    font-weight: bold !important;
}
@media (max-width: 1900px){
	.admin-curso-subtitle-2{
        font-size: 20px !important;
        margin-top: 10px !important;
    }
}
@media (max-width: 500px){
    .admin-curso-subtitle-2{
        font-size: 18px !important;
    }
}
.admin-curso-maestro-img{
    background-clip: content-box, border-box !important;
    background-image:   linear-gradient(#88b923, #659ED4), 
                        linear-gradient(to right, #88b923, #659ED4) !important;
    background-image:   linear-gradient(var(--primary), #659ED4), 
                        linear-gradient(to right, var(--primary), #659ED4) !important;
    background-origin: border-box !important;
    border: double 3px transparent !important;
    border-radius: 50% !important;
    height: 90px !important;
    width: 90px !important;
}
@media(max-width: 1900px){
    .admin-curso-maestro-img{
        height: 65px !important;
        width: 65px !important;
    }
}
.admin-curso-title-side{
    font-size: 18px;
    color: #b5b5b5f8;
}
.admin-curso-side-item{
    text-align: center;
    margin-left: 15px;
    border-right: solid #b7b7b7 1px;
    padding-right: 10px;
}
.admin-curso-caracteristica{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 10px;
    font-size: 15px;
    border-bottom: solid 1px #d8d8d8;
}
.admin-curso-categorias{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}
.admin-curso-tag{
    background-color: #88b923 !important;
    background-color: var(--primary) !important;
    color: #fff !important;
    padding: 2px 10px !important;
    border-radius: 5pc !important;
    margin: 0px 10px;
}
.row-padding{
    padding: 20px 0px !important;
}
.admin-curso-img{
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
@media (max-width: 1900px){
    .admin-curso-img{
        height: 150px;
    }
}
.admin-curso-act{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 10px;
}
.admin-curso-responisve{
    display: none !important;
}
@media (max-width: 500px){
    .admin-curso-responisve{
        display: -webkit-flex !important;
        display: flex !important;
    }
}
.admin-curso-responsive-item{
    margin: 10px 0px;
}

.curso-skeleton{
    height: 200px !important;
     width: 300px !important;
}

@media(max-width: 1900px){
    .curso-skeleton{
        width: 275px !important;
    }
}

    /*Valoraciones -> Inicio*/
    .admin-curso-valoracion-container:after{
        content: "";
        border-bottom: 1.5px solid #dddddd;
        display: block;
    }
    .admin-curso-valoracion-item{
        padding: 20px;
    }
    @media (max-width: 500px){
        .admin-curso-valoracion-item{
            padding: 15px;
        }
    }
    .admin-curso-valoracion-info{
        display: -webkit-flex;
        display: flex;
    }
    .admin-curso-valoracion-img{
        border-radius: 50% !important;
        height: 60px !important;
        margin-right: 20px;
        width: 60px !important;
    }
    .admin-curso-valoracion-nombre{
        font-size: 20px;
        font-weight: bold;
    }
    @media (max-width: 1900px){
        .admin-curso-valoracion-nombre{
            font-size: 18px;
        }
    }
    .admin-curso-valoracion-iniciales{
        -webkit-align-items: center;
                align-items: center;
        background-color: #88b923;
        background-color: var(--primary);
        border-radius: 50% !important;
        color: #fff;
        display: -webkit-flex;
        display: flex;
        font-size: 25px;
        -webkit-justify-content: center;
                justify-content: center;
        margin-right: 20px;
        height: 60px !important;
        width: 60px !important;
    }
    .admin-curso-valoracion-fecha{
        color: #a8a8a8ce;
        font-size: 12px;
    }
    .admin-curso-comment{
        margin-top: 10px;
        font-size: 18px;
    }
    /*Valoraciones -> Fin*/

/*Curso -> Fin*/

/*Estadísticas -> Inicio*/
.admin-estadisticas-card{
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin: 10px 0px;
}
@media (max-width: 500px){
    .admin-estadisticas-card{
        padding: 10px 20px;
    }
}
.admin-estadisticas-titulo{
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}
.admin-estadisticas-subtitulo{
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
.admin-estadisticas-container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}
.admin-estadisticas-item{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.admin-estadisticas-data{
    color: #c1c1c1;
    font-size: 28px;
    text-align: center;
}
.admin-estadisticas-icon{
    font-size: 40px !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
    -webkit-align-items: center !important;
            align-items: center !important;
    border: 3px solid;
    border-radius: 100%;
    margin: auto;
    width: 70px;
    height: 70px;
}
.admin-estadisticas-procentaje{
    font-size: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    border: 3px solid;
    border-radius: 100%;
    margin: auto;
    width: 70px;
    height: 70px;
}
.red{
    color: #ff0000;
}
.green{
    color: #80c783;
}
/*Estadisticas -> Fin*/

/* General */
.centrar{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.text-center{
    text-align: center;
}
/* General */
/* Layout */
.header-container{
    position: fixed;
    z-index: 10;
    width:100%;
}
.content-container{
    padding-top: 64px;
}
.content-main-container{
    min-height: calc(100vh - 64px);
}
.map-container{
    width: calc(100vw - 17px);
    height: 100vh;
    background-image: url(/static/media/Mapa.06279438.webp);
    background-size: 90%;
    background-position-x: calc(50% + 60px);
    background-position-y: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 0;
}
.footer-container{
    padding: 0px !important;
}
/* Layout */
/* Navbar */
.header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-image: linear-gradient(to bottom, var(--navbar_primary_color), var(--navbar_secondary_color)) !important;
    box-shadow: none !important;
    padding: 0px !important;
}
.header-search-container{
    width: 90%;
    text-align: center;
}
.header-search-input{
    border-radius: 20px !important;
    width: 85% !important;
}
.header-icon{
    color: var(--navbar_text_color);
}
.header-drawer-container{
    display: none;
}
.header-drawer-carpet{
    display: none;
}
@media (max-width: 768px) {
    .header-drawer-container{
        display: block;
    }
    .header-drawer-carpet{
        display: block;
    }
}
.header-drawer .ant-drawer-content{
    background-color: var(--background_color);
}
.header-drawer-nombre{
    font-size: 18px;
    margin: 3px 0px 0px 0px;
    color: var(--navbar_text_color);
}
.header-drawer-email{
    font-size: 12px;
    color: var(--navbar_text_color);
}
.header-drawer-hr{
    margin: 0;
    border: solid 1px var(--navbar_text_color);
}
.header-drawer-item{
    cursor: pointer;
    margin-top: 10px;
}
.header-drawer-image{
    width: 30px !important;
    height: 30px !important;
    margin-right: 5px;
}
.header-drawer-text{
    font-size: 14px;
    color: var(--navbar_text_color);
}
/* Navbar */
/* Footer */
.footer-login{
    background-color: var(--background_color);
}
.footer-icon-container{
    padding-top: 10px;
    padding-bottom: 10px;
}
.footer-icon{
    color: var(--footer_text_color);
}
.footer-text{
    color: var(--footer_text_color);
    text-align: center;
}
/* Footer */
/* Sidebar */
.content-sidebar{
    background-color: var(--sidebar_background_color) !important;
    z-index: 1;
}
@media (max-width: 768px) {
    .content-sidebar{
        display: none;
    }
}
.sideber-item{
    cursor: pointer;
    margin-top: 10px;
}
.sidebar-user-img{
    width: 60px !important;
    height: 60px !important;
    border-radius: 100%;
    border: 2px solid var(--primary) !important;
}
.sidebar-text{
    color: var(--sidebar_text_color);
    text-align: center;
    font-size: 12px;
}
/* Sidebar */
/*-----------usuario-perfil------*/
.imagen-perfil{
    display:'block';
    width: 70% !important;
    margin: 30px auto ; 
}
.menu-item{
    display: inline-block !important; 
    -webkit-align-items: center !important; 
            align-items: center !important;
    text-align: center !important; 
}
.menu-item:hover{
    color: var(--primary) !important;
}
.item{
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
}
.link:hover{color: var(--primary) !important;
  
}
.layout{
    padding-left: 0 !important;
    width: 100% !important;
    height: 50% !important
   
}

.tabs{
    color: var(--primary);
    font-size: 16px; 
}
.sider{
    background-color: white !important;
    margin: 16px;
}
/*----------------------------------------------------------*/

 /*----------------------esto se usa en ActividadesUsuario.js----------------*/
 .p-card:hover {
    cursor: pointer;
}
/*------------------------------------------------------------------------*/

/*----------------------------eso se usa en FormUsuario.js---------------------*/
@media only screen and (max-width: 1000px) {
    .inputs{
        max-width: 285px !important;
        max-height: 100px  !important;
    }
    .ant-tabs-nav-list{
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
        max-width: 285px !important;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
        background-color:white;
    }
    .ant-tabs-tab{
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
    .layout,.layout-usuario{
        padding-left: 0 !important;
    }
    .btn-forms{
        background-color: var(--primary) !important;
        border: 1px solid var(--primary) !important;
        float: left;
    }
}

.img-holder{
    margin: auto;
    width: 206px;
    height: 206px;
    border: 3px solid black;
    border-radius: 5px;
    margin-top: 1rem;
}
.image-upload{
    margin: 1p 1px 1px 1px;
    width: 206px;
    height: 30px;
    background-color: black;
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
#input[type="file"]{
    display: none;
}
.label{
    width: 100%;
    margin-top: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

/*-------------------------------------------------------------------------------

/*------------------------------------esto se usa en MaestroScreen.js--------------*/
.btn-atras{
    padding: 20px 20px 0px !important;
    color: var(--primary) !important;
    font-size: 20px !important;
    font-weight: bold !important;
    display: inline;
}
.icon-atras{
    font-size: 17px;
}
.admin-maestro-card{
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px #8888;
    border-radius: 10px;
    margin: 20px;
    padding: 50px 50px 20px;
}
.admin-maestro-info{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.admin-maestro-img{
    height: 200px !important;
    width: 200px !important;
    border: double 5px transparent;
    border-radius: 50%;
    background-image:   linear-gradient(var(--primary), #659ED4), 
                        linear-gradient(to right, var(--primary), #659ED4);
    background-origin: border-box;
    background-clip: content-box, border-box;
}
.admin-maestro-iniciales{
    height: 200px !important;
    width: 200px !important;
    border-radius: 50%;
    font-size: 100px;
    background-color: var(--primary);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
}
.admin-maestro-nombre{
    font-size: 25px;
    padding: 20px 20px 0px;
}
.bold{
    font-weight: bold !important;
}
.admin-maestro-profesion{
    font-size: 18px;
    color: #888;
}
.admin-maestro-resumen{
    border: solid 1px #c5c5c588;
    margin: 20px 15px;
    padding: 20px;
}
.maestro-cursos-texto{
    font-size: 30px !important;
    color: #999;
    margin: 15px !important;
}
.maestro-cursos{
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.card-header{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid gray !important;
}
.no-disponible{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 20px;
    font-size: 20px;
    width: 100%;
}

/*------------------------MaestrosScreen.js-------------------------------*/
.input-buscador{
    font-size: 25px !important;
    height: 40px !important;
    width: 100%;
    margin-top: 20px;
}
.search-icon{
    color:#9ed52b  !important;
    font-size: 20px;
}
.maestro-card{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.maestro-card-img{
    width: 90px !important;
    height: 90px !important;
    border-radius: 50% !important;
    border: double 3px transparent !important;
    background-image:   linear-gradient(var(--primary), #659ED4), 
                        linear-gradient(to right, var(--primary), #659ED4) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
}
.maestro-card-iniciales{
    width: 90px !important;
    height: 90px !important;
    border-radius: 50% !important;
    font-size: 40px;
    background-color: var(--primary);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
}
.maestro-card-nombre{
    font-size: 20px;
}
.maestro-card-profesion{
    font-size: 15px;
    color:#999;
}
.maestro-card-skeleton{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;   
}

.maestro-skeleton-img{
    width: 90px !important;
    height: 90px !important;
    margin-bottom: 10px;
}
.maestros-filtros-card{
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.maestros-filtros{
    color: var(--primary);
    font-size: 20px;
    padding: 20px;
}
/*-------------------------------------------------------------------*/

/*--------------------------PasswUsuario.js--------------------------------------*/
.btn-forms{
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    float: left !important;
}
/*-----------------------------------------*/
/*ViewCursoUsuuario.js -> Inicio */
.card-view-curso{
    border-radius: 10px !important;
    box-shadow: 6px 5px 13px 0px #9595951f;
}
.image-container {
    border-radius: 15px 15px 0px 0px;
    position: relative;
    width: 100%;
    height: 200px !important;
}
.image-container > .ant-image > .ant-image-img{
    border-radius: 10px 10px 0px 0px !important;
}
.btn-view-curso{
    background-color: var(--primary) !important;
    color: white !important;
    border-radius: 5px !important;
    font-weight: bold !important;
    font-size: 18px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}
.btn-view-curso:hover{
    background-color: var(--primary_hover) !important;
    border-color: transparent !important;
}
.info-view-curso{
    color: #000 !important;
    margin-top: 10px;
}
.titulo-view-curso{
    border: none !important;
    font-size: 38px;
    font-weight: bold;
}
.subtitulo-view-curso{
    margin-top: 20px;
    font-size: 30px;
}
.desc-view-curso{
    border: none !important;
    padding: 0px !important;
    font-size: 19px !important;
    color: grey !important;
}
.desc-view-curso[disabled]{
    cursor: default !important;
}
/*ViewCursoUsuuario.js -> Inicio */
.input-desc{
    border: 1px solid rgb(220, 220, 220) !important;
    height: 127px !important;
}
.input-desc:hover {
    border: 1px solid rgb(220, 220, 220) !important;
}

.input-desc:focus {
    box-shadow: none !important;
}
.btn-preg-resp {
    border-color: var(--primary)  !important;
    color:var(--primary) !important;
    font-weight: bold !important;
}

@media (min-width: 770px) and (max-width: 950px) {
    .btn-preg-resp{
        font-size: 1.3vw !important;
    }
}


/* Index Cursos */

.index-cursos-usuario {
    /* background-color: #0f172f; */
    width: 100%;
    min-height: calc(100vh - 60px);
}
.ant-layout{
    background: transparent !important;
}

.forotxt:focus{
    outline: none !important;
}



/* Boton Reset Curso */

.btn-reset-curso {
    background-color: var(--primary) !important;
    color: white !important;
}

.btn-reset-curso:focus {
    outline: none !important;
    box-shadow: none !important;
}

.hola{
    padding: 3% 0% 3% 3% !important; 
}
@media only screen and (max-width: 500px) {
    .hola{
        padding: 3% 3% 3% 3% !important;
    }
}

.hola-view-make-course{
    padding: 3% 0% 3% 0% !important; 
}




@media only screen and (max-width: 500px) {
    .edip{
        visibility: hidden !important;
        
    }
}
.viewMakeCourse-actividad-titulo{
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding: 10px 3%;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(90%);
    overflow: hidden;
}
.viewMakeCourse-actividad-end-text{
    padding: 10px 10%;
    color: #fff;
    font-size: 18px;
    text-align: justify;
}
.viewMakeCourse-actividad-descarga-text{
    padding: 10px 10%;
    color: #fff;
    font-size: 12px;
    text-align: center;
}
.viewMakeCourse-actividad-back-text{
    padding: 10px 10%;
    color: #fff;
    font-size: 12px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}
.viewMakeCourse-actividad-content{
    height: 60vh;
    overflow: auto;
}
.viewMakeCourse-titulo{
    color: #fff;
    margin-bottom: 5px;
    font-size: 16px;
}
.viewMakeCourse-img{
    width: 50px;
    height: 50px;
    background-color: khaki;
    border-radius: 50%;
    margin-right: 15px;
}
.viewMakeCourse-iniciales{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    font-size: 24px;
    background-color: var(--primary);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
}
.viewMakeCourse-docente-img{
    width: 20px;
    height: 20px;
    background-color: khaki;
    border-radius: 50%;
}
.viewMakeCourse-actividad{
    cursor: pointer !important;
}
.viewMakeCourse-actividad-disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.viewMakeCourse-docente-nombre{
    color: gray;
    font-size: 14px;
}
.viewMakeCourse-tema{
    color: #fff;
    border-bottom: 0.5px solid #263658;
    padding: 10px 20px 10px 5px;
    margin-left: 10px;
    min-height: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: calc(95%);
    overflow: hidden;
}
.viewMakeCourse-tema-cursor{
    cursor: default;
}
.ant-layout-sider-collapsed > .ant-layout-sider-children > .viewMakeCourse-act{
    padding-left: 2px !important;
}
.viewMakeCourse-act{
    padding-left: 15px;
}
.viewMakeCourse-numero{
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0px 10px;
    font-size: 12px;
    line-height: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: rgb(82 103 140);
    color: rgb(27 42 73);
    font-weight: bold;
    margin-left: 10px;
}
.viewMakeCourse-numero-done{
    background-color: rgb(137 191 36);
}
.viewMakeCourse-numero-actual{
    background-color: #fff !important;
}
.viewMakeCourse-sider-btn{
    background-color: rgb(50 162 255) !important;
    border: none !important;
    border-radius: 0px !important;
    color: rgb(4 12 28)!important;
    width: 44px !important;
    padding: 0px !important;
    height: 50px !important;
    min-width: 44px !important;
}
.viewMakeCourse-sider-titulo{
    color: #fff;
    padding: 10px;
    text-overflow: ellipsis;
    white-space:nowrap;
    display:inline-block;
    width: calc(90%);
    overflow: hidden;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.viewMakeCourse-preguntar-container{
    margin: 15px;
    background-color:  #1b2a49 !important;
    border-radius: 5px !important;
}
.viewMakeCourse-preguntar{
    padding: 15px !important;
}
.viewMakeCourse-preguntar > .ant-form-item{
    margin: 0px !important;
}
.viewMakeCourse-preguntar input{
    background-color: #0f172f !important;
    color: #405377 !important;
    border: 1px solid #405377 !important;
    padding: 10px 10px;
    margin: 0px;
}
.viewMakeCourse-preguntar input:focus{
    color: #405377 !important;
}
.viewMakeCourse-pregunta{
    background-color: rgb(27, 42, 73);
    padding: 10px;
    margin: 15px;
    border-radius: 5px;
    color: #fff;
}
.viewMakeCourse-numero-calificar{
    background-color: rgb(50, 162, 255);
    border: none; 
    color: white;
    width: 200px;
}
.viewMakeCourse-progress-div{
    margin: 15px;
}
.viewMakeCourse-progress-container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.viewMakeCourse-progress-title{
    font-size: 16px;
    color: var(--primary);
    text-align: center;
}
.viewMakeCourse-progress-percentage{
    font-size: 14px;
    color: #FFF;
    text-align: center;
    margin-top: 5px;
}
.viewMakeCourse-progress-reset-btn{
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    margin-top: 5px;
}
.viewMakeCourse-rate-title{
    font-size: 14px;
    color: var(--primary);
    text-align: center;
}
.viewMakeCourse-rate-msg{
    color: #888;
    font-size: 12px;
}
.viewMakeCourse-rate-value{
    color: #FFF;
    font-size: 15px;
    margin-right: 3px;
}
.pregunta-delete-icon:hover{
    color: #383838;
}
.viewMakeCourse-pregunta-img{
    border-radius: 50% !important;
    height: 40px !important;
    width: 40px !important;
}
.viewMakeCourse-pregunta-texto{
    font-size: 18px;
    font-weight: bold;
    margin-left: 60px;
}
.pregunta-usuario{
    font-size: 15px;
    float: right;
}
.pregunta-movil{
    display: none;
}
.ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: 2px solid #32a2ff !important;
    opacity: 0.5;
}
.viewMakeCourse-activities-buttons{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: right;
            justify-content: right;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 3%;
    padding-left: 3%;
    margin-top: 10px;
}
.usuarioTextScreen-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab{
    background: transparent !important;
    border: none !important;
    border-bottom: 2px solid transparent !important;
    width: 200px;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 0.5;
}
.usuarioTextScreen-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active{
    background: transparent !important;
    border: none !important;
    border-bottom: 2px solid #32a2ff !important;
    width: 200px;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 1 !important;
}
.usuarioTextScreen-content{
    padding: 50px;
}
.usuarioTextScreen-content p{
    background-color: transparent !important;
    color: white !important;
}
.usuarioTextScreen-nombre{
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    margin: 20px 0px 15px 0px;
}
.usuarioTextScreen-tab{
    color: #32a2ff;
}
.usuarioTextScreen-recurso-nombre{
    font-size: 14px;
    margin-left: 10px;
    color: #fff;
}
.usuarioTextScreen-recurso-nombre a {
    color: #fff;
}
.usuarioTextScreen-btn-prev{
    border: 1px solid rgb(50 162 255) !important;
    color: rgb(50 162 255) !important;
    background: transparent !important;
    border-radius: 2px 0px 0px 2px !important;
}
.usuarioTextScreen-btn-prev.ant-btn:hover{
    border-color: rgb(50 162 255) !important;
}
.usuarioTextScreen-btn-next{
    color: #0f172f !important;
    background-color: rgba(50 162 255) !important;
    border-color: rgb(50 162 255) !important;
    font-weight: 500 !important;
    border-radius: 0px 2px 2px 0px !important;
}
.usuarioTextScreen-btn-next-disabled{
    color: #0f172f !important;
    background-color: rgb(50 162 255 / 74%) !important;
    border-color: rgb(50 162 255 / 74%) !important;
    font-weight: 500 !important;
    border-radius: 0px 2px 2px 0px !important;
}
.usuarioQuizScreen-pregunta{
    background-color: rgb(27, 42, 73) !important;
    border: rgb(27, 42, 73) !important
}
.usuarioQuizScreen-pregunta-correcta{
    background-color: rgb(27, 42, 73) !important;
    border-color: rgba(136, 185, 35, 0.6) !important;
}
.usuarioQuizScreen-pregunta-incorrecta{
    background-color: rgb(27, 42, 73) !important;
    border-color: rgba(255, 0, 0, 0.6) !important;
}
.usuarioQuizScreen-pregunta > .ant-card-head{
    background: rgb(82 103 140) !important;
    border-bottom: rgb(82 103 140) !important;
    color: #fff !important;
}
.usuarioQuizScreen-pregunta-correcta > .ant-card-head{
    background: rgb(82 103 140) !important;
    border-bottom: rgba(136, 185, 35, 0.6) !important;
    color: #fff !important;
}
.usuarioQuizScreen-pregunta-incorrecta > .ant-card-head{
    background: rgb(82 103 140) !important;
    border-bottom: rgba(255, 0, 0, 0.6) !important;
    color: #fff !important;
}
.ant-radio-disabled + span{
    color: #FFF !important;
}
.usuarioQuizScreen-pregunta span{
    color: #fff !important;
}
aside.ant-layout-sider.ant-layout-sider-dark::-webkit-scrollbar {
    display: none;
}
/*Video tracker*/
.usuarioVideoScreen-video-range {
    height: 25px;
    -webkit-appearance: none;
    background: transparent;
}
.usuarioVideoScreen-video-range:focus {
    outline: none;
}
.usuarioVideoScreen-video-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: transparent;
    border-radius: 1px;
    border: 0px solid #000000;
}
.usuarioVideoScreen-video-range::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid var(--primary);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: var(--primary_hover);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}
.usuarioVideoScreen-video-range:focus::-webkit-slider-runnable-track {
    background: transparent;
}
.usuarioVideoScreen-video-range::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: transparent;
    border-radius: 1px;
    border: 0px solid #000000;
}
.usuarioVideoScreen-video-range::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid var(--primary);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: var(--primary_hover);
    cursor: pointer;
}
.usuarioVideoScreen-video-range::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.usuarioVideoScreen-video-range::-ms-fill-lower {
    background: var(--primary);
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
.usuarioVideoScreen-video-range::-ms-fill-upper {
    background: var(--primary);
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
.usuarioVideoScreen-video-range::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid var(--primary);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: var(--primary_hover);
    cursor: pointer;
}
.usuarioVideoScreen-video-range:focus::-ms-fill-lower {
    background: var(--primary);
}
.usuarioVideoScreen-video-range:focus::-ms-fill-upper {
    background: var(--primary);
}
.usuarioVideoScreen-video-range-volume {
    height: 25px;
    -webkit-appearance: none;
    background: transparent;
}
.usuarioVideoScreen-video-range-volume:focus {
    outline: none;
}
.usuarioVideoScreen-video-range-volume::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: transparent;
    border-radius: 1px;
    border: 0px solid #000000;
}
.usuarioVideoScreen-video-range-volume::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid white;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}
.usuarioVideoScreen-video-range-volume:focus::-webkit-slider-runnable-track {
    background: transparent;
}
.usuarioVideoScreen-video-range-volume::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: transparent;
    border-radius: 1px;
    border: 0px solid #000000;
}
.usuarioVideoScreen-video-range-volume::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid white;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: white;
    cursor: pointer;
}
.usuarioVideoScreen-video-range-volume::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
.usuarioVideoScreen-video-range-volume::-ms-fill-lower {
    background: white;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
.usuarioVideoScreen-video-range-volume::-ms-fill-upper {
    background: white;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
}
.usuarioVideoScreen-video-range-volume::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid white;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: white;
    cursor: pointer;
}
.usuarioVideoScreen-video-range-volume:focus::-ms-fill-lower {
    background: white;
}
.usuarioVideoScreen-video-range-volume:focus::-ms-fill-upper {
    background: white;
}
.usuarioVideoScreen-video-range-progress{
    height: 5px;
    background-color: var(--primary);
}
.usuarioVideoScreen-video-range-progress-volume{
    height: 5px;
    background-color: white;
}
.usuarioVideoScreen-video-range-loaded{
    height: 5px;
    background-color: #9f9f9f7d;
}
.usuarioVideoScreen-video-range-full{
    height: 5px;
    background-color: #9a9a9a17;
    width: 100%;
}
.usuarioVideoScreen-video-range-container{
    position: absolute;
    width: 100%;
    height: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0;
    left: 0;
}
.usuarioTextScreen-btn-player{
    color: #fff !important;
    background-color: transparent !important;
    border: none !important;
    font-weight: 500 !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 25px !important;
}
.usuarioTextScreen-btn-player-disabled{
    opacity: 0.5;
}
.usuarioVideoScreen-error-text{
    color: white;
}
.usuarioVideoScreen-transition-container{
    position: absolute;
    left: 0;
    top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgb(0 0 0 / 80%);
    color: white !important;
}
.usuarioVideoScreen-transition-icon{
    font-size: 40px;
}
.usuarioVideoScreen-transition-info{
    font-size: 34px;
    font-weight: bold;
}
.usuarioVideoScreen-transition-cancel{
    font-size: 20px; 
    text-decoration: underline;
    cursor: pointer;
}
.usuarioVideoScreen-controls-container{
    position: absolute;
    left: 0;
    bottom: 0;
}
.usuarioVideoScreen-controls-button-icon{
    color: white;
    font-size: 20px;
}
.usuarioVideoScreen-controls-time{
    display: inline;
    color: white;
    float: right;
    margin-right: 10px;
}
/*Video tracker*/
.docente-container{
    min-height: calc(100vh - 64px -268px);
    padding: 2% 3% 2% 3%;
}

/* Sider */
.docente-sider-menu-item {
    color: var(--primary) !important;
    background-color: white !important;
}

.docente-sider-link {
    color: grey !important;
}

.docente-sider-link:active{
    color: var(--primary) !important
}

.docente-sider-link:hover{
    color: var(--primary) !important
}


/* Auth */
.docente-auth-card {
    background-color: #ffffff !important;
    box-shadow: 0 0 10px #b1b1b1;
    max-height: 500px;
    text-align: center;
    padding: 30px 20px 30px 20px;
    max-width: 300px;
}

.docente-auth-card h2 {
    color: var(--primary); 
}

/* /docente/courses -> Inicio */

    /* DocenteCursosTabScreen.js -> Inicio */
    .tab-docente-cursos{
        border-top: 2px var(--primary);
    }

    .tabs-container-curso > .ant-tabs > .ant-tabs-nav{
        margin: 0px !important;
    }
    .tabs-container-curso > .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
        border: none !important;
        font-size: 16px !important;
    }
    .ant-tabs-card.docente-tabs > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
        background-color: #f0f2f5 !important;
        transition: none !important;
    }
    .tabs-container-curso > .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        background-color: #fff !important;
        border-top: 3px solid var(--primary) !important;
        font-weight: 500;
    }
    /* DocenteCursosTabScreen.js -> Fin */

    /*TabInformacionCurso.js -> Inicio */
    .info-item-docente{
        display: -webkit-flex;
        display: flex;
        -webkit-align-content: center;
                align-content: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-top: 15px;
    }
    /*TabInformacionCurso.js -> Fin */

    /*TabUsuariosEnrolados.js -> Inicio */
    .tab-usuarios-img{
        background-color: #f0f0f0;
        height: 45px;
        width: 45px;
        border-radius: 100%;
        margin-right: 10px;
    }
    /*TabUsuariosEnrolados.js -> Fin */

    /*TabReviewaCurso.js -> Inicio */
    .tab-reviews-img{
        width: 60px !important;
        height: 60px !important;
        border-radius: 50% !important;
        margin-right: 20px;
    }
    .tab-reviews-card{
        margin: 20px;
        padding: 20px;
        box-shadow: 2px 2px 5px 2px #e1e1e1;
    }
    .tab-reviews-review{
        padding-top: 20px;
        font-size: 16px;
    }
    /*TabReviewsCurso.js -> Fin */
/* /docente/courses -> Fin */



/* Index Cursos */

.card-titulo{
    display: block;
    display: -webkit-box;
    height: 16px * 1.4 * 2;
    font-size: 16px;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-maestro-footer{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}


/* Curso Docente */

.crud-cursos {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; 
    width: 100%;
    padding: 60px 8% 60px 8%;
}

.input-titulo {
    overflow: hidden;
    font-size: 16px !important;
}

.input-titulo:focus {
    box-shadow: none !important;
}

.input-desc{
    border: 1px solid rgb(220, 220, 220) !important;
    height: 70px !important;
}

.input-desc:hover {
    border: 1px solid rgb(220, 220, 220) !important;
}

.input-desc:focus {
    box-shadow: none !important;
}

.avatar-uploader > .ant-upload {
    width: 100% !important;
    height: 200px !important;
  }

.btn-preg-resp {
    border-color: var(--primary)  !important;
    color:var(--primary) !important;
    font-weight: bold !important;
}

@media (min-width: 770px) and (max-width: 950px) {
    .btn-preg-resp{
        font-size: 1.3vw !important;
    }
}

.nombre-actividad{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.p-card-selected{
    background-color: #dcdcdc !important;
}

/* Portada Curso */

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.image-container .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    padding: 10px 10px 0 0;
    text-align: right;
}
.image-container:hover .after {
    display: block;
    background: rgba(187, 187, 187, 0.5);
}

/* Input Precio Curso*/

.docente-input-precio-curso{
    border-bottom: 1px solid var(--primary); 
    border-left: none;
    border-right: none;
    border-top: none;
    max-width: 90px;
    width: 100%;
}

.docente-input-precio-curso:focus {
    outline: none;
}


/* Preguntas y Respuestas */

.preguntas-none{
    margin: 0px !important;
    font-size: 25px;
    color: #b2b2b2;
    text-align: center;
}
.pregunta-card{
    background-color:#f2f2f2;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 25px;
}
.pregunta-desktop{
    display: -webkit-flex;
    display: flex;
}
.pregunta-icon{
    float: right;
    font-size: 15px;
    cursor: pointer;
    color: #8d8d8d;
}
.pregunta-delete-icon:hover{
    color: #383838;
}
.pregunta-img-usuario{
    border-radius: 50% !important;
    height: 65px !important;
    width: 65px !important;
}
.pregunta-texto{
    font-size: 20px;
    font-weight: bold;
}
.pregunta-usuario{
    font-size: 15px;
    float: right;
}
.pregunta-movil{
    display: none;
}
@media (max-width: 900px){
    .pregunta-movil{
        display: block;
    }
    .pregunta-desktop{
        display: none;
    }
    .pregunta-usuario-movil{
        font-size: 15px;
        float: right;
        margin-left: 10px;
    }
    .pregunta-texto-movil{
        font-size: 15px;
        font-weight: bold;
        margin-top: 10px;
    }
    .pregunta-img-usuario{
        height: 45px !important;
        width: 45px !important;
    }
}

/*------------------foro del maestro/----------------------*/

.forotxt:focus{
    outline: none !important;

}


/* Recursos */
.recurso-a{
    color: #000;
}
.recurso-a:hover{
    color: #000;
}
.recurso-a:active{
    color: #000;
}
/* Recursos */



/* Importaciones */

/*Variables*/
:root {
    --primary: #94CA6F;
    --secondary: #659ED4;
    --primary_hover: #90C86A;
    --secondary_hover: #B475D1;
    --primary_light: #94CA6F;
    --primary_opacity: #F4F9F0;
    --secondary_opacity: #659ED4;
    --background_color: #000;
    --sidebar_background_color: #000;
    --sidebar_text_color: #FFF;
    --sidebar_folder: 1;
    --sidebar_btn_user_primary_color: #000;
    --sidebar_btn_user_secondary_color: #3bfcb4;
    --footer_text_color: #FFF;
    --navbar_text_color: #FFF;
    --navbar_border: 1;
    --navbar_primary_color: #3bfcb4;
    --navbar_secondary_color: #000;
    --navbar_line: none;
}
/*Variables*/

/* Generales */
.text-center{
    text-align: center;
}
.w-100{
    width: 100%;
}
.h-100{
    height: 100%;
}
.p-relative{
    position: relative;
}
.d-flex{
    display: -webkit-flex;
    display: flex;
}
.centrar{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.centrar-v{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.float-r{
    float: right;
}
.float-l{
    float: left;
}
.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}
.mt-15{
    margin-top: 15px;
}
.mt-20{
    margin-top: 20px;
}
.mb-5{
    margin-bottom: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-15{
    margin-bottom: 15px;
}
.mb-20{
    margin-bottom: 20px;
}
/* Generales */

/* Sobreescribir estilos de antdesign */
.ant-btn:hover{
    border-color: transparent !important;
}
/* Sobreescribir estilos de antdesign */

@media (max-width: 767px) {
    .pass-card {
        width: 90% !important;
    }
}

* {
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important; */
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}

h3 {
    color: #94CA6F !important;
    color: var(--primary) !important;
}

/* componentes generales */
.btn-primary{
    background-color: #94CA6F !important;
    background-color: var(--primary) !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 18px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    min-width: 130px !important;
    padding: 5px 15px !important;
    border-radius: 32px;
    font-family: "Century Gothic", sans-serif !important;
}
.btn-gradiente{
    background-image: linear-gradient(to right, #659ED4, #94CA6F) !important;
    background-image: linear-gradient(to right, var(--secondary), var(--primary)) !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 18px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    width: 130px !important;
    padding: 5px 15px !important;
    border-radius: 32px;
}
.btn-gradiente span, .btn-primary span{
    font-family: "Century Gothic", sans-serif !important;
}
.btn-login {
    margin-top: 10px;
    background-color: #94CA6F !important;
    background-color: var(--primary) !important;
    border-color: #94CA6F !important;
    border-color: var(--primary) !important;
    float: left;
}

.auth-center{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; 
    height: 100vh;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100vw;
}

.btn-link-agregar:hover {
    color: #94CA6F !important;
    color: var(--primary) !important;
}


/* CRUDS general */

.crud-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; 
    /* height: 100vh; */
    padding: 2% 10% 0 10%;
}

.btn-crud-opciones {
    background-color: #94CA6F !important;
    background-color: var(--primary) !important;
    color: white !important;
    border: none !important;
}

.btn-crud-opciones:hover {
    border: none !important;
}


/* header */

.header {
    position: relative;
    height: 60px;
    background-image: linear-gradient(to bottom, #3bfcb4, #000) !important;
    background-image: linear-gradient(to bottom, var(--navbar_primary_color), var(--navbar_secondary_color)) !important;
    box-shadow: none !important;
    color: #94CA6F;
    color: var(--primary);
    width: 100%;
}

.header-principal {
    padding: 0 20px 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

.logo {
    height: 75%;
}

.acceder-btn {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: #94CA6F !important;
    background-color: var(--primary) !important;
    border: none !important;
    font-family: "Century Gothic", sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}
/* SIDER */

/* FOOTER */

.footer-logout{
    background-color: #000 !important;
    background-color: var(--background_color) !important;
    color: #fff !important;
    display: -webkit-flex;
    display: flex;
    font-size: 15px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.footer-logo{
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 30%;
    margin-bottom: 10px;
    object-fit: contain;
}
.footer-icon{
    font-size: 26px;
    margin-left: 10px;
    margin-right: 10px;
}
.footer-titulo{
    font-size: 20px;
}
.footer-card{
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center !important;
            align-items: center !important;
    margin-left: 10px;
}
.footer-links{
    font-size: 18px;
    -webkit-align-items: center;
            align-items: center;
}

@media (max-width: 991px){
    .footer-card{
        margin-bottom: 40px;
       
    }
    .footer-logo{
        height: 60%;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        margin: auto;
        margin-left: 47px !important;
    }
    .footer-links{
        border: none;
    }
}
@media (max-width: 576px){
    .footer-links{
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
}

/* Content */
.content-main-container{
    background-color: #000;
    background-color: var(--background_color);
}

/* Home */
.banner-1{
    height: 100vh;
    width: 100%;
}
.banner-1-content{
    top: -75vh;
    position: relative;
    z-index: 1;
}
.banner-1-titulo{
    font-size: 5pc;
    color: #fff;
    margin-bottom: 0px;
}
.banner-1-subtitulo{
    font-size: 4pc;
    color: #fff;
}
.carousel-1{
    /* top: 65px !important;
    left: 0px !important;
    height: 100vh !important;
    width: 100vw !important;
    z-index: 1 !important;
    max-width: 100% !important; */
}
.carousel-1-img{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 100vw;
    height: 100vh;
}
.btn-home-registrate{
    background-color: #94CA6F !important;
    background-color: var(--primary) !important;
    border: none !important;
    color: white !important;
    font-size: 25px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.btn-home-ingresar-maestro{
    background-color: white !important;
    border: none !important;
    color: #94CA6F !important;
    color: var(--primary) !important;
    font-size: 25px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.btn-home-registrate:hover{
    background-color: #6f961d !important;
}

.home-categ-card{
    height: 120px;
    text-align: center;
    color: #fff;
    background: #94CA6F;
    background: var(--primary);
    margin: 0px 10px;
    font-size: 18px;
}
.home-categ-card-nombre{
    height: 100% !important;
    width: 80% !important;
    margin: auto !important;
}
.slick-arrow{
    background-color: transparent !important;
    border: none !important;
    height: 120px !important;
    color: white !important;
    margin-top: -60px !important;
    position: absolute !important;
    width: 30px !important;
    z-index: 1 !important;
}
.slick-arrow:hover{
    background-color: rgba(78, 105, 23, .1) !important;
    color: white !important;
}
.ant-carousel .slick-next {
    right: 10px !important;
}
.ant-carousel .slick-prev {
    left: 10px !important;
}
span.anticon.anticon-right.slick-arrow.slick-next, span.anticon.anticon-left.slick-arrow.slick-prev{
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
    -webkit-align-items: center;
            align-items: center;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    font-size: 20px !important;
    color: #fff !important;
}
.maestro-card-home{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.maestro-card-img-home{
    width: 90px !important;
    height: 90px !important;
    border-radius: 50% !important;
    border: double 3px transparent !important;
    background-image:   linear-gradient(#94CA6F, #659ED4), 
                        linear-gradient(to right, #94CA6F, #659ED4) !important;
    background-image:   linear-gradient(var(--primary), #659ED4), 
                        linear-gradient(to right, var(--primary), #659ED4) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    margin-left: 10px;
}
.maestro-card-nombre-home{
    font-size: 20px;
    text-align: center;
}
.header-1{
    margin-top: 60px;
    font-size: 30px;
    color: #94CA6F;
    color: var(--primary);
}
.banner-2{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: -webkit-flex;
    display: flex;
    height: 300px;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    left: 0px;
    margin-bottom: 1000px;
    max-width: 100%;
    position: absolute;
    width: 100vw;
}
.banner-2::before{
    position: absolute;
    background: rgba(0,0,0,.6);
    left: 0;
    content: "";
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
}
.banner-2-cantidad{
    font-size: 80px;
    color: #94CA6F;
    color: var(--primary);
}
.banner-2-subtitulo{
    color: #fff;
    font-size: 40px;
    margin-top: -30px;
}

/*responsive*/
@media (max-width: 700px){
    .banner-1-titulo{
        font-size: 40px;
    }
    .banner-1-subtitulo{
        font-size: 25px;
    }
}
@media (min-width: 1900px){
    .banner-1, .carousel-1-img, .carousel-1{
        height: 60vh !important;
    }
    .banner-1-content{
        top: -45vh;
    }
}

/* Pass */

.pass-card {
    background-color: #ffffff !important;
    box-shadow: 0 0 10px #b1b1b1;
    height: 300px;
    padding: 30px 20px 30px 20px;
    width: 500px;
}

.pass-card h2 {
    color: #94CA6F;
    color: var(--primary); 
}

.pass-card p {
    font-size: 16px;
}

/* Políticas */

.politicas-banner{
    height: 300px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px;
}
.politicas-title{
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}

h1.politicas{
    text-align: center !important;
    font-size: 40px !important;
    font-weight: 900 !important;
    color: #333333 !important;
    width: 100%;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}
.text-style{
    font-size: 19px !important;
    text-align: justify !important;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif !important;
    padding-bottom: 25px !important;
    color: #333333 !important;
    font-weight: 300 !important;
}

/* */

.no-padding{
    padding: 0px !important;
}
/*---------------------maestro---------------------------*/
.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

 
.layout{
    width: 100% !important;
    height: 100% !important;
}
 .layout-admin{
    box-sizing: content-box;
    width: 100%;
    min-height: 100%;
}
  
.sider-info{
    text-align: center !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 18px !important;
}

.info{
    color: #94CA6F;
    color: var(--primary);
    font-size: 20px;
    font-weight: bold;
}

.auth-center{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; 
    height: 100vh;
    -webkit-justify-content: center;
            justify-content: center;
}

/* Admin -> Inicio */
    .active-menu{
        background-color: #94CA6F42;
        background-color: var(--primary)42;
        color: #333;
    } 
  
    /* Skeleton loading -> Inicio */
    .skeleton{
        opacity: 0.7 !important;
        -webkit-animation: skeleton-loading 1s linear infinite alternate !important;
                animation: skeleton-loading 1s linear infinite alternate !important;
    }
    @-webkit-keyframes skeleton-loading {
        0% {
            background-color: #cdcdcd;
        }

        100% {
            background-color: #999999;
        }
    }
    @keyframes skeleton-loading {
        0% {
            background-color: #cdcdcd;
        }

        100% {
            background-color: #999999;
        }
    }
    .skeleton.text{
        width: 100%;
        height: .5rem;
        margin-bottom: .25rem;
        border-radius: 1.25rem;
    }
    /* Skeleton loading -> Fin */

    /* Admin Maestros -> Inicio */  
    .admin-maestros-nombre{
        padding-left: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }
    .admin-maestros-img{
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        margin-right: 20px;
    }
    .admin-maestros-inicales{
        font-size: 20px;
        color: #fff;
        background-color: #94CA6F;
        background-color: var(--primary);
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .center{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
    /* Admin Maestros -> Fin */  

    .admin-crud-filter{
        display: -webkit-flex;
        display: flex;
        margin-top: -20px;
        margin: 15px 0px;
    }
/* Admin -> Fin */

/* Collapse */

.ant-collapse-content-box {
    padding: 0 !important;
}
