.docente-container{
    min-height: calc(100vh - 64px -268px);
    padding: 2% 3% 2% 3%;
}

/* Sider */
.docente-sider-menu-item {
    color: var(--primary) !important;
    background-color: white !important;
}

.docente-sider-link {
    color: grey !important;
}

.docente-sider-link:active{
    color: var(--primary) !important
}

.docente-sider-link:hover{
    color: var(--primary) !important
}


/* Auth */
.docente-auth-card {
    background-color: #ffffff !important;
    box-shadow: 0 0 10px #b1b1b1;
    max-height: 500px;
    text-align: center;
    padding: 30px 20px 30px 20px;
    max-width: 300px;
}

.docente-auth-card h2 {
    color: var(--primary); 
}

/* /docente/courses -> Inicio */

    /* DocenteCursosTabScreen.js -> Inicio */
    .tab-docente-cursos{
        border-top: 2px var(--primary);
    }

    .tabs-container-curso > .ant-tabs > .ant-tabs-nav{
        margin: 0px !important;
    }
    .tabs-container-curso > .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
        border: none !important;
        font-size: 16px !important;
    }
    .ant-tabs-card.docente-tabs > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
        background-color: #f0f2f5 !important;
        transition: none !important;
    }
    .tabs-container-curso > .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        background-color: #fff !important;
        border-top: 3px solid var(--primary) !important;
        font-weight: 500;
    }
    /* DocenteCursosTabScreen.js -> Fin */

    /*TabInformacionCurso.js -> Inicio */
    .info-item-docente{
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-top: 15px;
    }
    /*TabInformacionCurso.js -> Fin */

    /*TabUsuariosEnrolados.js -> Inicio */
    .tab-usuarios-img{
        background-color: #f0f0f0;
        height: 45px;
        width: 45px;
        border-radius: 100%;
        margin-right: 10px;
    }
    /*TabUsuariosEnrolados.js -> Fin */

    /*TabReviewaCurso.js -> Inicio */
    .tab-reviews-img{
        width: 60px !important;
        height: 60px !important;
        border-radius: 50% !important;
        margin-right: 20px;
    }
    .tab-reviews-card{
        margin: 20px;
        padding: 20px;
        box-shadow: 2px 2px 5px 2px #e1e1e1;
    }
    .tab-reviews-review{
        padding-top: 20px;
        font-size: 16px;
    }
    /*TabReviewsCurso.js -> Fin */
/* /docente/courses -> Fin */



/* Index Cursos */

.card-titulo{
    display: block;
    display: -webkit-box;
    height: 16px * 1.4 * 2;
    font-size: 16px;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-maestro-footer{
    display: flex;
    justify-content: space-between;
}


/* Curso Docente */

.crud-cursos {
    display: flex;
    flex-direction: column; 
    width: 100%;
    padding: 60px 8% 60px 8%;
}

.input-titulo {
    overflow: hidden;
    font-size: 16px !important;
}

.input-titulo:focus {
    box-shadow: none !important;
}

.input-desc{
    border: 1px solid rgb(220, 220, 220) !important;
    height: 70px !important;
}

.input-desc:hover {
    border: 1px solid rgb(220, 220, 220) !important;
}

.input-desc:focus {
    box-shadow: none !important;
}

.avatar-uploader > .ant-upload {
    width: 100% !important;
    height: 200px !important;
  }

.btn-preg-resp {
    border-color: var(--primary)  !important;
    color:var(--primary) !important;
    font-weight: bold !important;
}

@media (min-width: 770px) and (max-width: 950px) {
    .btn-preg-resp{
        font-size: 1.3vw !important;
    }
}

.nombre-actividad{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.p-card-selected{
    background-color: #dcdcdc !important;
}

/* Portada Curso */

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.image-container .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    padding: 10px 10px 0 0;
    text-align: right;
}
.image-container:hover .after {
    display: block;
    background: rgba(187, 187, 187, 0.5);
}

/* Input Precio Curso*/

.docente-input-precio-curso{
    border-bottom: 1px solid var(--primary); 
    border-left: none;
    border-right: none;
    border-top: none;
    max-width: 90px;
    width: 100%;
}

.docente-input-precio-curso:focus {
    outline: none;
}


/* Preguntas y Respuestas */

.preguntas-none{
    margin: 0px !important;
    font-size: 25px;
    color: #b2b2b2;
    text-align: center;
}
.pregunta-card{
    background-color:#f2f2f2;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 25px;
}
.pregunta-desktop{
    display: flex;
}
.pregunta-icon{
    float: right;
    font-size: 15px;
    cursor: pointer;
    color: #8d8d8d;
}
.pregunta-delete-icon:hover{
    color: #383838;
}
.pregunta-img-usuario{
    border-radius: 50% !important;
    height: 65px !important;
    width: 65px !important;
}
.pregunta-texto{
    font-size: 20px;
    font-weight: bold;
}
.pregunta-usuario{
    font-size: 15px;
    float: right;
}
.pregunta-movil{
    display: none;
}
@media (max-width: 900px){
    .pregunta-movil{
        display: block;
    }
    .pregunta-desktop{
        display: none;
    }
    .pregunta-usuario-movil{
        font-size: 15px;
        float: right;
        margin-left: 10px;
    }
    .pregunta-texto-movil{
        font-size: 15px;
        font-weight: bold;
        margin-top: 10px;
    }
    .pregunta-img-usuario{
        height: 45px !important;
        width: 45px !important;
    }
}

/*------------------foro del maestro/----------------------*/

.forotxt:focus{
    outline: none !important;

}


/* Recursos */
.recurso-a{
    color: #000;
}
.recurso-a:hover{
    color: #000;
}
.recurso-a:active{
    color: #000;
}
/* Recursos */

