:root {
    --primary: #88b923;
    --primary_hover: #a2f100;
    --secondary: #659ED4;
  }

/*      */
.centrar{
    display: flex;
    justify-content: center;
}
.admin-container{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 67px) !important;
    padding: 2% 10% 0 10%;
}
.admin-input-buscador{
    font-size: 25px !important;
    height: 40px !important;
    width: 100%;
}
.admin-btn-atras{
    padding: 20px 0px !important;
    color: #88b923 !important;
    font-size: 20px !important;
    font-weight: bold !important;
    display: inline;
}
/*      */


/* Login -> Inicio */
.login-admin{
    background-image: url('../assets/loginAdmin.png');
    background-repeat: no-repeat;
    background-size: cover;
    
}
.auth-center-admin{
    display: flex;
    flex-direction: column; 
    height: 100vh;
    justify-content: center;
    padding-left: 15%;
}
/* Login -> Fin */

/* Navbar -> Inicio */
.navbar-admin{
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-image: linear-gradient(90deg, var(--primary), var(--secondary)) 0 0 100%;
}
.icon-menu-navbar-clicked{
    color: #000 !important;
    font-size: 20px !important;
    transform: translateX(170px);
    transition: all .25s;
}
.icon-menu-navbar{
    color: #000 !important;
    font-size: 20px !important;
    transition: all .25s;
}
.logout-navbar{
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 20px !important;
}
.logout-navbar:hover{
    color: var(--primary) !important;
}

/*Navbar -> Fin */

/* Sidebar -> Inicio */
.sider-admin{
    background-color: #fff !important;
    padding: 10px 0px !important;
}
.usuario-container-sidebar{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    font-size: 20px;
}
.titulo-sidebar{
    color: #6a6a6a;
}
.subtitulo-sidebar{
    color: #aeaeae;
    font-size: 17px;
    margin-top: -5px !important;
}
.icon-user-sidebar{
    font-size: 70px;
    background-color: #6a6a6a;
    border-radius: 50%;
    padding: 5px;
    color: #fff !important;
}
.menu-item-sidebar{
    border-bottom: 2px solid #dcdcdc !important;
}
.icon-menu-sidebar{
    font-size: 20px !important;
    color: var(--primary) !important;
}
/* Sidebar -> Fin */

/* CRUDS general -> Inicio */
.admin-crud-titulo{
    font-size: 45px;
    text-align: right;
    margin-bottom: 0px !important;
    color: var(--primary);
}
.nuevo-boton{
    background-color: var(--primary) !important;
    border: none !important;
    color: #fff !important;
    font-size: 20px !important;
    margin-right: 20px !important;
    padding: 5px 50px !important;
    width: fit-content !important;
    height: 40px !important;
}
.nuevo-boton:hover{
    background-color: #9ed52b  !important;
    border: none !important;
    color: #fff !important;
}
.input-buscador{
    border: none !important;
    font-size: 25px !important;
    height: 40px !important;
    width: 100%;
}
.search-icon{
    color:#9ed52b  !important;
    font-size: 20px;
}
.ant-pagination-item-active, .ant-pagination-item-active a{
    border-color: #9ed52b  !important;
    color: #9ed52b  !important;
}
.ant-pagination-item:hover, .ant-pagination-item:hover a{
    border-color: #9ed52b  !important;
    color: #9ed52b  !important;
}
/* CRUDS general -> Fin */

/*Footer -> Inicio*/
.admin-footer-container{
    margin-top: auto;
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #787878;
    background-color: #f0f2f5;
    padding: 30px;
}
.icon-footer-admin{
    color: #787878 !important;
    font-size: 30px;
}
/*Footer -> Fin*/

/*Crud cursos -> Inicio*/
.admin-cursos-img{
    height: 170px;
}
@media (max-width: 1900px){
    .admin-cursos-img{
        height: 120px;
    }
}
/*Crud cursos -> Fin*/

@media (max-width: 500px){
    .crud-center{
        height: fit-content;
    }
}

/*Curso -> Inicio*/
.admin-curso-card{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 50px;
    height: 100%;
    margin: 20px 0px;
}
@media (max-width: 500px){
    .admin-curso-card{
        padding: 10px 20px;
    }
}
.admin-curso-outline{
    padding: 5px 20px;
    border: solid #d9d9d9 1px;
    height: 100%;
}
.admin-curso-title{
    font-size: 50px !important;
}
@media (max-width: 1900px){
    .admin-curso-title{
        font-size: 45px !important;
    }
}
@media (max-width: 500px){
    .admin-curso-title{
        font-size: 35px !important;
        text-align: center;
    }
    .admin-curso-side{
        display: none !important;
    }
}
.admin-curso-subtitle{
    font-size: 25px !important;
    color: #8e8f8f;
}
@media (max-width: 500px){
    .admin-curso-subtitle{
        font-size: 20px !important;
        color: #8e8f8f;
    }
}
.admin-curso-subtitle-2{
    cursor: pointer !important;
    font-size: 22px !important;
    margin: 20px 0px !important;
}
.admin-curso-subtitle-2:hover{
    color: var(--primary) !important;
    opacity: 70% !important;
    background: none !important;
}
.admin-curso-subtitle-2:active{
    background: none !important;
}
.admin-curso-subtitle-2-selected{
    font-weight: bold !important;
}
@media (max-width: 1900px){
	.admin-curso-subtitle-2{
        font-size: 20px !important;
        margin-top: 10px !important;
    }
}
@media (max-width: 500px){
    .admin-curso-subtitle-2{
        font-size: 18px !important;
    }
}
.admin-curso-maestro-img{
    background-clip: content-box, border-box !important;
    background-image:   linear-gradient(var(--primary), #659ED4), 
                        linear-gradient(to right, var(--primary), #659ED4) !important;
    background-origin: border-box !important;
    border: double 3px transparent !important;
    border-radius: 50% !important;
    height: 90px !important;
    width: 90px !important;
}
@media(max-width: 1900px){
    .admin-curso-maestro-img{
        height: 65px !important;
        width: 65px !important;
    }
}
.admin-curso-title-side{
    font-size: 18px;
    color: #b5b5b5f8;
}
.admin-curso-side-item{
    text-align: center;
    margin-left: 15px;
    border-right: solid #b7b7b7 1px;
    padding-right: 10px;
}
.admin-curso-caracteristica{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 15px;
    border-bottom: solid 1px #d8d8d8;
}
.admin-curso-categorias{
    display: flex;
    justify-content: flex-start;
}
.admin-curso-tag{
    background-color: var(--primary) !important;
    color: #fff !important;
    padding: 2px 10px !important;
    border-radius: 5pc !important;
    margin: 0px 10px;
}
.row-padding{
    padding: 20px 0px !important;
}
.admin-curso-img{
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
@media (max-width: 1900px){
    .admin-curso-img{
        height: 150px;
    }
}
.admin-curso-act{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}
.admin-curso-responisve{
    display: none !important;
}
@media (max-width: 500px){
    .admin-curso-responisve{
        display: flex !important;
    }
}
.admin-curso-responsive-item{
    margin: 10px 0px;
}

.curso-skeleton{
    height: 200px !important;
     width: 300px !important;
}

@media(max-width: 1900px){
    .curso-skeleton{
        width: 275px !important;
    }
}

    /*Valoraciones -> Inicio*/
    .admin-curso-valoracion-container:after{
        content: "";
        border-bottom: 1.5px solid #dddddd;
        display: block;
    }
    .admin-curso-valoracion-item{
        padding: 20px;
    }
    @media (max-width: 500px){
        .admin-curso-valoracion-item{
            padding: 15px;
        }
    }
    .admin-curso-valoracion-info{
        display: flex;
    }
    .admin-curso-valoracion-img{
        border-radius: 50% !important;
        height: 60px !important;
        margin-right: 20px;
        width: 60px !important;
    }
    .admin-curso-valoracion-nombre{
        font-size: 20px;
        font-weight: bold;
    }
    @media (max-width: 1900px){
        .admin-curso-valoracion-nombre{
            font-size: 18px;
        }
    }
    .admin-curso-valoracion-iniciales{
        align-items: center;
        background-color: var(--primary);
        border-radius: 50% !important;
        color: #fff;
        display: flex;
        font-size: 25px;
        justify-content: center;
        margin-right: 20px;
        height: 60px !important;
        width: 60px !important;
    }
    .admin-curso-valoracion-fecha{
        color: #a8a8a8ce;
        font-size: 12px;
    }
    .admin-curso-comment{
        margin-top: 10px;
        font-size: 18px;
    }
    /*Valoraciones -> Fin*/

/*Curso -> Fin*/

/*Estadísticas -> Inicio*/
.admin-estadisticas-card{
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin: 10px 0px;
}
@media (max-width: 500px){
    .admin-estadisticas-card{
        padding: 10px 20px;
    }
}
.admin-estadisticas-titulo{
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}
.admin-estadisticas-subtitulo{
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
.admin-estadisticas-container{
    display: flex;
    justify-content: space-evenly;
}
.admin-estadisticas-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.admin-estadisticas-data{
    color: #c1c1c1;
    font-size: 28px;
    text-align: center;
}
.admin-estadisticas-icon{
    font-size: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 3px solid;
    border-radius: 100%;
    margin: auto;
    width: 70px;
    height: 70px;
}
.admin-estadisticas-procentaje{
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 3px solid;
    border-radius: 100%;
    margin: auto;
    width: 70px;
    height: 70px;
}
.red{
    color: #ff0000;
}
.green{
    color: #80c783;
}
/*Estadisticas -> Fin*/
