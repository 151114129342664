/* Importaciones */
@import 'styles/admin.css';
@import 'styles/usuario.css';
@import 'styles/docente.css';
@import 'styles/adminCursoScreen.css';

/*Variables*/
:root {
    --primary: #94CA6F;
    --secondary: #659ED4;
    --primary_hover: #90C86A;
    --secondary_hover: #B475D1;
    --primary_light: #94CA6F;
    --primary_opacity: #F4F9F0;
    --secondary_opacity: #659ED4;
    --background_color: #000;
    --sidebar_background_color: #000;
    --sidebar_text_color: #FFF;
    --sidebar_folder: 1;
    --sidebar_btn_user_primary_color: #000;
    --sidebar_btn_user_secondary_color: #3bfcb4;
    --footer_text_color: #FFF;
    --navbar_text_color: #FFF;
    --navbar_border: 1;
    --navbar_primary_color: #3bfcb4;
    --navbar_secondary_color: #000;
    --navbar_line: none;
}
/*Variables*/

/* Generales */
.text-center{
    text-align: center;
}
.w-100{
    width: 100%;
}
.h-100{
    height: 100%;
}
.p-relative{
    position: relative;
}
.d-flex{
    display: flex;
}
.centrar{
    display: flex;
    justify-content: center;
}
.centrar-v{
    display: flex;
    align-items: center;
}
.float-r{
    float: right;
}
.float-l{
    float: left;
}
.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}
.mt-15{
    margin-top: 15px;
}
.mt-20{
    margin-top: 20px;
}
.mb-5{
    margin-bottom: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-15{
    margin-bottom: 15px;
}
.mb-20{
    margin-bottom: 20px;
}
/* Generales */

/* Sobreescribir estilos de antdesign */
.ant-btn:hover{
    border-color: transparent !important;
}
/* Sobreescribir estilos de antdesign */

@media (max-width: 767px) {
    .pass-card {
        width: 90% !important;
    }
}

* {
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important; */
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}

h3 {
    color: var(--primary) !important;
}

/* componentes generales */
.btn-primary{
    background-color: var(--primary) !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 18px !important;
    height: fit-content !important;
    min-width: 130px !important;
    padding: 5px 15px !important;
    border-radius: 32px;
    font-family: "Century Gothic", sans-serif !important;
}
.btn-gradiente{
    background-image: linear-gradient(to right, var(--secondary), var(--primary)) !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 18px !important;
    height: fit-content !important;
    width: 130px !important;
    padding: 5px 15px !important;
    border-radius: 32px;
}
.btn-gradiente span, .btn-primary span{
    font-family: "Century Gothic", sans-serif !important;
}
.btn-login {
    margin-top: 10px;
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    float: left;
}

.auth-center{
    align-items: center;
    display: flex;
    flex-direction: column; 
    height: 100vh;
    justify-content: center;
    width: 100vw;
}

.btn-link-agregar:hover {
    color: var(--primary) !important;
}


/* CRUDS general */

.crud-center {
    display: flex;
    flex-direction: column; 
    /* height: 100vh; */
    padding: 2% 10% 0 10%;
}

.btn-crud-opciones {
    background-color: var(--primary) !important;
    color: white !important;
    border: none !important;
}

.btn-crud-opciones:hover {
    border: none !important;
}


/* header */

.header {
    position: relative;
    height: 60px;
    background-image: linear-gradient(to bottom, var(--navbar_primary_color), var(--navbar_secondary_color)) !important;
    box-shadow: none !important;
    color: var(--primary);
    width: 100%;
}

.header-principal {
    padding: 0 20px 0 20px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.logo {
    height: 75%;
}

.acceder-btn {
    height: fit-content !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: var(--primary) !important;
    border: none !important;
    font-family: "Century Gothic", sans-serif !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}
/* SIDER */

/* FOOTER */

.footer-logout{
    background-color: var(--background_color) !important;
    color: #fff !important;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
}
.footer-logo{
    align-items: center;
    justify-content: center;
    height: 30%;
    margin-bottom: 10px;
    object-fit: contain;
}
.footer-icon{
    font-size: 26px;
    margin-left: 10px;
    margin-right: 10px;
}
.footer-titulo{
    font-size: 20px;
}
.footer-card{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    margin-left: 10px;
}
.footer-links{
    font-size: 18px;
    align-items: center;
}

@media (max-width: 991px){
    .footer-card{
        margin-bottom: 40px;
       
    }
    .footer-logo{
        height: 60%;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-left: 47px !important;
    }
    .footer-links{
        border: none;
    }
}
@media (max-width: 576px){
    .footer-links{
        align-items: flex-start;
    }
}

/* Content */
.content-main-container{
    background-color: var(--background_color);
}

/* Home */
.banner-1{
    height: 100vh;
    width: 100%;
}
.banner-1-content{
    top: -75vh;
    position: relative;
    z-index: 1;
}
.banner-1-titulo{
    font-size: 5pc;
    color: #fff;
    margin-bottom: 0px;
}
.banner-1-subtitulo{
    font-size: 4pc;
    color: #fff;
}
.carousel-1{
    /* top: 65px !important;
    left: 0px !important;
    height: 100vh !important;
    width: 100vw !important;
    z-index: 1 !important;
    max-width: 100% !important; */
}
.carousel-1-img{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 100vw;
    height: 100vh;
}
.btn-home-registrate{
    background-color: var(--primary) !important;
    border: none !important;
    color: white !important;
    font-size: 25px !important;
    height: fit-content !important;
}

.btn-home-ingresar-maestro{
    background-color: white !important;
    border: none !important;
    color: var(--primary) !important;
    font-size: 25px !important;
    height: fit-content !important;
}

.btn-home-registrate:hover{
    background-color: #6f961d !important;
}

.home-categ-card{
    height: 120px;
    text-align: center;
    color: #fff;
    background: var(--primary);
    margin: 0px 10px;
    font-size: 18px;
}
.home-categ-card-nombre{
    height: 100% !important;
    width: 80% !important;
    margin: auto !important;
}
.slick-arrow{
    background-color: transparent !important;
    border: none !important;
    height: 120px !important;
    color: white !important;
    margin-top: -60px !important;
    position: absolute !important;
    width: 30px !important;
    z-index: 1 !important;
}
.slick-arrow:hover{
    background-color: rgba(78, 105, 23, .1) !important;
    color: white !important;
}
.ant-carousel .slick-next {
    right: 10px !important;
}
.ant-carousel .slick-prev {
    left: 10px !important;
}
span.anticon.anticon-right.slick-arrow.slick-next, span.anticon.anticon-left.slick-arrow.slick-prev{
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    font-size: 20px !important;
    color: #fff !important;
}
.maestro-card-home{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.maestro-card-img-home{
    width: 90px !important;
    height: 90px !important;
    border-radius: 50% !important;
    border: double 3px transparent !important;
    background-image:   linear-gradient(var(--primary), #659ED4), 
                        linear-gradient(to right, var(--primary), #659ED4) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    margin-left: 10px;
}
.maestro-card-nombre-home{
    font-size: 20px;
    text-align: center;
}
.header-1{
    margin-top: 60px;
    font-size: 30px;
    color: var(--primary);
}
.banner-2{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 300px;
    justify-content: space-evenly;
    left: 0px;
    margin-bottom: 1000px;
    max-width: 100%;
    position: absolute;
    width: 100vw;
}
.banner-2::before{
    position: absolute;
    background: rgba(0,0,0,.6);
    left: 0;
    content: "";
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
}
.banner-2-cantidad{
    font-size: 80px;
    color: var(--primary);
}
.banner-2-subtitulo{
    color: #fff;
    font-size: 40px;
    margin-top: -30px;
}

/*responsive*/
@media (max-width: 700px){
    .banner-1-titulo{
        font-size: 40px;
    }
    .banner-1-subtitulo{
        font-size: 25px;
    }
}
@media (min-width: 1900px){
    .banner-1, .carousel-1-img, .carousel-1{
        height: 60vh !important;
    }
    .banner-1-content{
        top: -45vh;
    }
}

/* Pass */

.pass-card {
    background-color: #ffffff !important;
    box-shadow: 0 0 10px #b1b1b1;
    height: 300px;
    padding: 30px 20px 30px 20px;
    width: 500px;
}

.pass-card h2 {
    color: var(--primary); 
}

.pass-card p {
    font-size: 16px;
}

/* Políticas */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap');

.politicas-banner{
    height: 300px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    margin-bottom: 20px;
}
.politicas-title{
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}

h1.politicas{
    text-align: center !important;
    font-size: 40px !important;
    font-weight: 900 !important;
    color: #333333 !important;
    width: 100%;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}
.text-style{
    font-size: 19px !important;
    text-align: justify !important;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif !important;
    padding-bottom: 25px !important;
    color: #333333 !important;
    font-weight: 300 !important;
}

/* */

.no-padding{
    padding: 0px !important;
}
/*---------------------maestro---------------------------*/
.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

 
.layout{
    width: 100% !important;
    height: 100% !important;
}
 .layout-admin{
    box-sizing: content-box;
    width: 100%;
    min-height: 100%;
}
  
.sider-info{
    text-align: center !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 18px !important;
}

.info{
    color: var(--primary);
    font-size: 20px;
    font-weight: bold;
}

.auth-center{
    align-items: center;
    display: flex;
    flex-direction: column; 
    height: 100vh;
    justify-content: center;
}

/* Admin -> Inicio */
    .active-menu{
        background-color: var(--primary)42;
        color: #333;
    } 
  
    /* Skeleton loading -> Inicio */
    .skeleton{
        opacity: 0.7 !important;
        animation: skeleton-loading 1s linear infinite alternate !important;
    }
    @keyframes skeleton-loading {
        0% {
            background-color: #cdcdcd;
        }

        100% {
            background-color: #999999;
        }
    }
    .skeleton.text{
        width: 100%;
        height: .5rem;
        margin-bottom: .25rem;
        border-radius: 1.25rem;
    }
    /* Skeleton loading -> Fin */

    /* Admin Maestros -> Inicio */  
    .admin-maestros-nombre{
        padding-left: 20px;
        display: flex;
        align-items: center;
    }
    .admin-maestros-img{
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        margin-right: 20px;
    }
    .admin-maestros-inicales{
        font-size: 20px;
        color: #fff;
        background-color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .center{
        display: flex;
        justify-content: center;
    }
    /* Admin Maestros -> Fin */  

    .admin-crud-filter{
        display: flex;
        margin-top: -20px;
        margin: 15px 0px;
    }
/* Admin -> Fin */

/* Collapse */

.ant-collapse-content-box {
    padding: 0 !important;
}